import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faHome } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

const Header = () => {
  const [userMasterObject, setUserMasterObject] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state || {};

  const idType = state.device
    ? "deviceTokenId"
    : state.bu
    ? "buMasterId"
    : state.makeModelData
    ? "makeModelMasterId"
    : undefined;

  const {
    device = null,
    bu = null,
    makeModelData = null,
    vehicle = null,
    pageType = null,
  } = state;

  const queryParams = new URLSearchParams(location.search);
  const formType = queryParams.get("form"); // Extract formType from queryParams

  //Fail Notification
  const toastWarning = (message, seconds) => {
    toast.error(message, {
      position: "top-right",
      autoClose: seconds,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      style: { color: "red" },
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedUserData = sessionStorage.getItem("user");

        if (storedUserData) {
          const parsedUserData = JSON.parse(storedUserData);

          if (
            parsedUserData.entity &&
            parsedUserData.entity.roleOuObjs.length > 0
          ) {
            const ouMasterId =
              parsedUserData.entity.roleOuObjs[0].ouMasterObj.id;
            setUserMasterObject({
              firstName: parsedUserData.entity.firstName,
              lastName: parsedUserData.entity.lastName,
              designation: parsedUserData.entity.designation,
              ouMasterId,
            });
          } else {
            toastWarning("User data not available.", 3000);
            return;
          }
        } else {
          navigate(`/`);
        }
      } catch (error) {
        console.error("Error in fetchData:", error);
      }
    };

    fetchData();
  }, [navigate]);

  const getTitle = (pathname, idType, device, bu, makeModelData) => {
    // Check if the idType is known
    let determinedIdType;
    const [, id, pathIdType] = pathname.split("/");
    if (
      pathIdType === "deviceTokenId" ||
      pathIdType === "buMasterId" ||
      pathIdType === "makeModelMasterId"
    ) {
      determinedIdType = pathIdType;
    } else {
      // Default to idType if no specific idType is found in the pathname
      determinedIdType = idType;
    }

    // For VehiclesTestedList Component
    if (pathname.startsWith("/vehiclesTested/")) {
      // Check if the determined idType matches the provided idType
      if (determinedIdType === idType && idType) {
        if (idType === "deviceTokenId" && device) {
          if (device.pageType === "usedDevices") {
            return `Devices/Used Devices/${device.deviceName}-${device.operatorName}`;
          } else if (device.pageType === "activeDevices") {
            return `Active Devices/${device.deviceName}-${device.operatorName}`;
          }
        } else if (idType === "buMasterId" && bu) {
          return `Service Stations/${bu.buName}-${bu.buLocation}(${bu.buCode})(${bu.buRegion})`;
        } else if (idType === "makeModelMasterId" && makeModelData) {
          return `Active Vehicles/${makeModelData.makeName}-${makeModelData.modelName}`;
        }
      }
    }
    // For WheelAnalysisReport Component
    // Check if the pathname matches the pattern for dynamic routes
    if (pathname.includes("/wheelsAnalysisReport/")) {
      // Check if the determined idType matches the provided idType
      if (determinedIdType === idType && idType) {
        if (idType === "deviceTokenId" && device) {
          if (device.pageType === "usedDevices") {
            return `Devices/Used Devices/${device.deviceName}-${device.operatorName}/Wheels Analysis/${vehicle.RegistrationNumber}`;
          } else if (device.pageType === "activeDevices") {
            return `Active Devices/${device.deviceName}-${device.operatorName}/Wheels Analysis/${vehicle.RegistrationNumber}`;
          }
        } else if (idType === "buMasterId" && bu) {
          return `Service Stations/${bu.buName}-${bu.buLocation}(${bu.buCode})(${bu.buRegion})/Wheels Analysis/${vehicle.RegistrationNumber}`;
        } else if (idType === "makeModelMasterId" && makeModelData) {
          return `Active Vehicles/${makeModelData.makeName}-${makeModelData.modelName}/Wheels Analysis/${vehicle.RegistrationNumber}`;
        }
      } else if (pageType === "AllVehiclesTestedList") {
        return `Tests/Wheels Analysis/${vehicle.RegistrationNumber}`;
      }
    }

    if (pageType === "AppDownloadPage") {
      return `Applications/Videos`;
    }

    // If the pathname doesn't match any specific case, return a default value
    switch (pathname) {
      case "/dashboard":
        return "Dashboard";
      case "/appDownloadPage":
        return "Applications";
      case "/serviceStations":
        return "Service Stations";
      case "/activeVehicles":
        return "Active Vehicles";
      case "/AllvehiclesTestedList":
        return "Tests";
      case "/unusedDevices":
        return "Devices/Unused Devices";
      case "/userGuide":
        return "Videos";
      case "/usedDevices":
        return "Devices/Used Devices";
      case "/activeDevices":
        return "Active Devices";
      case "/home":
        return "Home";
      case "/create":
        // return "Create";
        if (formType === "model") {
          return "Create MakeModel";
        } else if (formType === "serviceStation") {
          return "Create ServiceStation";
        }
        return "Create"; // Default title for 'Registered'
      case "/bulkUploadServiceStation":
        return "Create ServiceStation/Bulk Create";
      case "/registered":
        if (formType === "model") {
          // return "Registered Make Models";
          return "Registered MakeModel";
        } else if (formType === "serviceStation") {
          return "Registered ServiceStation";
        }
        return "Registered"; // Default title for 'Registered'
      default:
        return "Unknown Path";
    }
  };

  const title = getTitle(location.pathname, idType, device, bu, makeModelData);

  return (
    <div className="col-md-12 mx-0 px-0">
      <header
        style={{
          position: "sticky",
          top: "0",
          zIndex: "1000",
          backgroundColor: "white",
        }}
      >
        <div className="justify-content-between p-3 sticky sticky-header">
          <div className="left d-flex align-items-center">
            {/* <FontAwesomeIcon icon={faHome} size="lg" /> /{" "} */}
            <FontAwesomeIcon icon={faHome} size="lg" />
            {location.pathname !== "/dashboard" && " / "}
            <h4
              className="headerTitles"
              style={{ margin: "0", marginLeft: "5px" }}
            >
              {title}
            </h4>
          </div>

          <div className="right d-flex align-items-center">
            <FontAwesomeIcon icon={faUser} size="lg" />

            {userMasterObject && (
              <span className="mr-2">
                <h5
                  className="headerTitles"
                  style={{
                    marginLeft: "4px",
                    marginBottom: "0px",
                  }}
                >
                  Welcome: {userMasterObject.firstName}{" "}
                  {userMasterObject.lastName} ({userMasterObject.designation})
                </h5>
              </span>
            )}
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
