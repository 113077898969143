import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import { Button, Modal, Form } from "react-bootstrap";
import Header from "./Header";
import "react-toastify/dist/ReactToastify.css"; // Import styles for Toastify
import { serverUrl } from "../../App";
import { ClipLoader } from "react-spinners";
import Select from "react-select";

// export const Update = () => {
export const Update = ({
  selectedModel,
  showEditModal,
  setShowEditModal,
  handleUpdateComplete,
}) => {
  debugger;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [vehicleBodyTypeDropDown, setVehicleBodyTypeDropDown] = useState([]);
  const [selectedVehicleBodyType, setSelectedVehicleBodyType] = useState("");
  const [errorMessages, setErrorMessages] = useState({});
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showReConfirmDialog, setShowReConfirmDialog] = useState(false);
  const handleCancelDilog = () => {
    setShowConfirmDialog(false);
  };
  const handleCancelDilogBox = () => {
    setShowReConfirmDialog(false);
  };
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const [modelFormData, setModelFormData] = useState({
    makeName: "",
    modelName: "",
    frontWeight: "",
    backWeight: "",
    frontLeftSecondPeakAmplitudeThreshold: "",
    frontRightSecondPeakAmplitudeThreshold: "",
    backLeftSecondPeakAmplitudeThreshold: "",
    backRightSecondPeakAmplitudeThreshold: "",
    frontLeftMinSecondPeakAmplitudeThreshold: "",
    frontRightMinSecondPeakAmplitudeThreshold: "",
    backLeftMinSecondPeakAmplitudeThreshold: "",
    backRightMinSecondPeakAmplitudeThreshold: "",
  });
  // const [originalModelData, setOriginalModelData] = useState({
  //   ...modelFormData,
  // });

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await fetch(serverUrl + "get/all/bu/make_model", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({}),
        });
        const data = await response.json();

        if (data.entity != null) {
          if (
            data.entity.allVehicleBodyTypeList != null &&
            data.entity.allVehicleBodyTypeList.length > 0
          ) {
            setVehicleBodyTypeDropDown(data.entity.allVehicleBodyTypeList);
          } else {
            console.error("No Vehicle body type found: " + data.entity);
            toastWarning("Vehicle body type not found.", 3000);
          }
        } else if (data.errors != null) {
          let errorDescription = data.errors.errorDescription;
          toastWarning(errorDescription, 3000);
        } else {
          toastWarning("Something Went Wrong", 3000);
        }
      } catch (err) {
        console.error("Error in fetchOptions:", err);
      }
    };

    fetchOptions();
  }, []);

  // Fail Notification
  const toastWarning = (message, seconds) => {
    toast.error(message, {
      position: "top-right",
      autoClose: seconds,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      style: { color: "red" },
    });
  };

  //Validation for Model Form Fields
  const handleModelFormData = (event) => {
    const { name, value } = event.target;
    let errorMessage = "";

    if (value === "") {
      setModelFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
      return;
    }

    if (name === "makeName") {
      const isNameValid = /^[A-Za-z]+$/.test(value);
      if (isNameValid) {
        const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
        setModelFormData((prevData) => ({
          ...prevData,
          [name]: capitalizedValue,
        }));
      }
    } else if (name === "modelName") {
      const isModelValid = /^[A-Za-z][A-Za-z0-9\s\-().]*$/.test(value);
      if (isModelValid) {
        const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
        setModelFormData((prevData) => ({
          ...prevData,
          [name]: capitalizedValue,
        }));
      }
    } else if (name === "frontWeight" || name === "backWeight") {
      const weightRegex = /^\d{1,7}(\.\d{0,2})?$/; // Max 7 digits before and 2 after decimal
      if (weightRegex.test(value)) {
        setModelFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else if (
      [
        "frontLeftSecondPeakAmplitudeThreshold",
        "frontRightSecondPeakAmplitudeThreshold",
        "backLeftSecondPeakAmplitudeThreshold",
        "backRightSecondPeakAmplitudeThreshold",
        "frontLeftMinSecondPeakAmplitudeThreshold",
        "frontRightMinSecondPeakAmplitudeThreshold",
        "backLeftMinSecondPeakAmplitudeThreshold",
        "backRightMinSecondPeakAmplitudeThreshold",
      ].includes(name)
    ) {
      // Regex to match numbers with up to 11 total characters, including decimal point and optional leading minus sign
      const amplitudeRegex = /^-?\d+(\.\d{0,2})?$/;
      // Check if the total length is within 11 characters and only one minus sign is allowed at the start
      const isValidLength = value.length <= 11;
      const isValidSign = /^-?\d/.test(value); // Ensure minus is only at the start

      if (amplitudeRegex.test(value) && isValidLength && isValidSign) {
        setModelFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        setErrorMessages((prevErrors) => ({
          ...prevErrors,
          [name]: "", // Clear error message if valid
        }));
      } else {
        errorMessage = "Enter a valid threshold.";
        setErrorMessages((prevErrors) => ({
          ...prevErrors,
          [name]: errorMessage,
        }));
      }
    }
  };

  const preventNegativeSign = (event) => {
    const { value, selectionStart } = event.target;

    // Allow minus sign only if it's at the start of the input
    if (event.key === "-" && selectionStart === 0) {
      return; // Allow minus sign if at the start
    }

    // Block additional minus signs and other invalid characters
    if (event.key === "-" || event.key === "e" || event.key === "+") {
      event.preventDefault();
    }
  };

  // Check if all required fields are filled
  const isModelFormValid = () => {
    return (
      (modelFormData.makeName ||
        modelFormData.modelName ||
        modelFormData.frontWeight ||
        modelFormData.backWeight ||
        modelFormData.frontLeftSecondPeakAmplitudeThreshold ||
        modelFormData.frontRightSecondPeakAmplitudeThreshold ||
        modelFormData.backLeftSecondPeakAmplitudeThreshold ||
        modelFormData.backRightSecondPeakAmplitudeThreshold ||
        modelFormData.frontLeftMinSecondPeakAmplitudeThreshold ||
        modelFormData.frontRightMinSecondPeakAmplitudeThreshold ||
        modelFormData.backLeftMinSecondPeakAmplitudeThreshold ||
        modelFormData.backRightMinSecondPeakAmplitudeThreshold ||
        selectedVehicleBodyType) &&
      !Object.values(errorMessages).some((msg) => msg !== "")
    );
  };

  const handleAddClick = (e) => {
    // debugger;
    e.preventDefault();
    // setOriginalModelData(modelFormData);
    setShowConfirmDialog(true);
  };

  const handleConfirm = () => {
    setShowConfirmDialog(false);
    setShowReConfirmDialog(true);
    // sendDataUpdateModel();
  };

  const handleReConfirm = () => {
    setShowReConfirmDialog(false);
    sendDataUpdateModel();
  };

  const sendDataUpdateModel = async () => {
    // debugger;
    setIsLoading(true); // Set loading to true when sending data

    try {
      console.log("Id:", selectedModel.id);

      // Dynamically construct the request body
      let body = {
        makeModelMasterId: selectedModel.id, // Assuming this is required
      };

      // Conditionally add properties if they have values
      if (selectedVehicleBodyType?.id) {
        body.vehicleBodyTypeId = selectedVehicleBodyType.id;
      }
      if (modelFormData.makeName) {
        body.makeName = modelFormData.makeName;
      }
      if (modelFormData.modelName) {
        body.modelName = modelFormData.modelName;
      }
      if (modelFormData.frontWeight) {
        body.frontStandardWeight = modelFormData.frontWeight;
      }
      if (modelFormData.backWeight) {
        body.backStandardWeight = modelFormData.backWeight;
      }
      if (modelFormData.frontLeftSecondPeakAmplitudeThreshold) {
        body.frontLeftSecondPeakAmplitudeThreshold =
          modelFormData.frontLeftSecondPeakAmplitudeThreshold;
      }
      if (modelFormData.frontRightSecondPeakAmplitudeThreshold) {
        body.frontRightSecondPeakAmplitudeThreshold =
          modelFormData.frontRightSecondPeakAmplitudeThreshold;
      }
      if (modelFormData.backLeftSecondPeakAmplitudeThreshold) {
        body.backLeftSecondPeakAmplitudeThreshold =
          modelFormData.backLeftSecondPeakAmplitudeThreshold;
      }
      if (modelFormData.backRightSecondPeakAmplitudeThreshold) {
        body.backRightSecondPeakAmplitudeThreshold =
          modelFormData.backRightSecondPeakAmplitudeThreshold;
      }
      if (modelFormData.frontLeftMinSecondPeakAmplitudeThreshold) {
        body.frontLeftMinSecondPeakAmplitudeThreshold =
          modelFormData.frontLeftMinSecondPeakAmplitudeThreshold;
      }
      if (modelFormData.frontRightMinSecondPeakAmplitudeThreshold) {
        body.frontRightMinSecondPeakAmplitudeThreshold =
          modelFormData.frontRightMinSecondPeakAmplitudeThreshold;
      }
      if (modelFormData.backLeftMinSecondPeakAmplitudeThreshold) {
        body.backLeftMinSecondPeakAmplitudeThreshold =
          modelFormData.backLeftMinSecondPeakAmplitudeThreshold;
      }
      if (modelFormData.backRightMinSecondPeakAmplitudeThreshold) {
        body.backRightMinSecondPeakAmplitudeThreshold =
          modelFormData.backRightMinSecondPeakAmplitudeThreshold;
      }

      const response = await fetch(serverUrl + "makemodel/save", {
        // API call to send data...
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body), // Send the dynamically created body
      });

      if (response.ok) {
        const data = await response.json();

        if (data.entity != null) {
          showSuccessModelBox();
        } else if (data.errors != null) {
          //check data.errors if not null then show toast data.errors.errorDescription
          let errorDiscription = data.errors.errorDescription;

          toastWarning(errorDiscription, 3000);

          return;
        } else {
          toastWarning("Something Went Wrong", 3000);

          return;
        }
      } else {
        // Failed to send data
        console.error("Failed to send data");
      }
    } catch (error) {
      // Error occurred
      console.error("Error sending data:", error);
    } finally {
      // Set loading to false after API call is complete
      setIsLoading(false);
    }
  };

  // Function to handle modal visibility
  const showSuccessModelBox = () => {
    setIsSuccessModalVisible(true);
  };

  const handleCloseSuccessModal = () => {
    setShowEditModal(false);
    setIsSuccessModalVisible(false);
    handleUpdateComplete();
  };

  return (
    <>
      <div className="body-pd secondary-color">
        <div className="container-fluid px-0 mx-0">
          <div className="container-fluid mx-0 px-0">
            <ToastContainer />
            <div className="col-md-12 px-0">
              <div className="row px-0 mx-0">
                <div className="col-md-12 px-0">
                  <Header />
                </div>
              </div>

              <div className="row px-1 mx-0" style={{ marginTop: "20px" }}>
                <div style={{ filter: isLoading ? "blur(5px)" : "none" }}>
                  {/* Update Make Model Modal */}
                  <Modal
                    show={showEditModal}
                    onHide={() => setShowEditModal(false)}
                    dialogClassName="my-modal"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title className="text-center">
                        Update Make Model
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {selectedModel && (
                        <Form>
                          {/* Make Name and Model Name */}
                          <div
                            className="form-row"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: "25px",
                            }}
                          >
                            <div
                              className="form-group"
                              style={{ flex: "0 0 48%" }}
                            >
                              <label
                                htmlFor="makeName"
                                style={{ fontWeight: "bold" }}
                              >
                                Make Name
                              </label>

                              <input
                                type="text"
                                className="form-control"
                                name="makeName"
                                id="makeName"
                                placeholder="Enter Make Name"
                                value={modelFormData.makeName} // Bind value to formData
                                onChange={handleModelFormData}
                                required
                              />
                              {selectedModel.makeName && (
                                <div
                                  style={{
                                    fontSize: "1rem",
                                    color: "#6c757d",
                                    marginTop: "2px",
                                  }}
                                >
                                  {selectedModel.makeName}
                                </div>
                              )}
                            </div>

                            <div
                              className="form-group"
                              style={{ flex: "0 0 48%" }}
                            >
                              <label
                                htmlFor="modelName"
                                style={{ fontWeight: "bold" }}
                              >
                                Model Name
                              </label>

                              <input
                                type="text"
                                className="form-control"
                                name="modelName"
                                id="modelName"
                                placeholder="Enter Model Name"
                                value={modelFormData.modelName} // Bind value to formData
                                onChange={handleModelFormData}
                                required
                              />
                              {selectedModel.modelName && (
                                <div
                                  style={{
                                    fontSize: "1rem",
                                    color: "#6c757d",
                                    marginTop: "2px",
                                  }}
                                >
                                  {selectedModel.modelName}
                                </div>
                              )}
                            </div>
                          </div>

                          {/* Front Weight and Back Weight */}
                          <div
                            className="form-row"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: "25px",
                            }}
                          >
                            <div
                              className="form-group"
                              style={{ flex: "0 0 48%" }}
                            >
                              <label
                                htmlFor="frontWeight"
                                style={{ fontWeight: "bold" }}
                              >
                                Front Weight Distribution (kg)
                              </label>
                              <input
                                type="number"
                                step="0.01"
                                className="form-control"
                                name="frontWeight"
                                id="frontWeight"
                                placeholder="Enter Front Weight Distribution"
                                min="0.01"
                                value={modelFormData.frontWeight} // Bind value to formData
                                onChange={handleModelFormData}
                                onKeyDown={(e) => preventNegativeSign(e)}
                                required
                                onInput={(e) => {
                                  if (e.target.value < 0) e.target.value = "";
                                }}
                              />

                              {/* Display the standard weight for front wheels (left or right, since they're the same) */}
                              {
                                selectedModel.wheelMaster
                                  .filter(
                                    (wheel) => wheel.wheelPosition === "FRONT"
                                  )
                                  .map((wheel, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        fontSize: "1rem",
                                        color: "#6c757d",
                                        marginTop: "2px",
                                      }}
                                    >
                                      {wheel.standardWeight}
                                    </div>
                                  ))[0] /* Display only the first front wheel's standard weight */
                              }
                            </div>

                            <div
                              className="form-group"
                              style={{ flex: "0 0 48%" }}
                            >
                              <label
                                htmlFor="backWeight"
                                style={{ fontWeight: "bold" }}
                              >
                                Back Weight Distribution (kg)
                              </label>
                              <input
                                type="number"
                                step="0.01"
                                className="form-control"
                                name="backWeight"
                                id="backWeight"
                                placeholder="Enter Back Weight Distribution"
                                min="0.01"
                                value={modelFormData.backWeight} // Bind value to formData
                                onChange={handleModelFormData}
                                onKeyDown={(e) => preventNegativeSign(e)}
                                required
                                onInput={(e) => {
                                  if (e.target.value < 0) e.target.value = "";
                                }}
                              />
                              {/* Display the standard weight for front wheels (left or right, since they're the same) */}
                              {
                                selectedModel.wheelMaster
                                  .filter(
                                    (wheel) => wheel.wheelPosition === "BACK"
                                  )
                                  .map((wheel, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        fontSize: "1rem",
                                        color: "#6c757d",
                                        marginTop: "2px",
                                      }}
                                    >
                                      {wheel.standardWeight}
                                    </div>
                                  ))[0] /* Display only the first front wheel's standard weight */
                              }
                            </div>
                          </div>

                          {/* Second Peak Amplitude Thresholds */}

                          <div
                            className="form-row"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: "25px",
                            }}
                          >
                            <div
                              className="form-group"
                              style={{ flex: "0 0 48%" }}
                            >
                              <label
                                htmlFor="frontLeftMinSecondPeakAmplitudeThreshold"
                                style={{ fontWeight: "bold" }}
                              >
                                Front Left Min Second Peak Amplitude Threshold
                              </label>
                              <input
                                type="number"
                                step="0.01"
                                className="form-control"
                                name="frontLeftMinSecondPeakAmplitudeThreshold"
                                id="frontLeftMinSecondPeakAmplitudeThreshold"
                                placeholder="Enter Front Left Min Threshold"
                                value={
                                  modelFormData.frontLeftMinSecondPeakAmplitudeThreshold
                                } // Bind value to formData
                                onChange={handleModelFormData}
                              />
                              {
                                selectedModel.wheelMaster
                                  .filter(
                                    (wheel) =>
                                      wheel.wheelPosition === "FRONT" &&
                                      wheel.wheelSide === "LEFT"
                                  )
                                  .map((wheel, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        fontSize: "1rem",
                                        color: "#6c757d",
                                        marginTop: "2px",
                                      }}
                                    >
                                      {wheel.minSecondPeakAmplitudeThreshold}
                                    </div>
                                  ))[0] /* Display only the first front wheel's standard weight */
                              }
                            </div>

                            <div
                              className="form-group"
                              style={{ flex: "0 0 48%" }}
                            >
                              <label
                                htmlFor="frontRightMinSecondPeakAmplitudeThreshold"
                                style={{ fontWeight: "bold" }}
                              >
                                Front Right Min Second Peak Amplitude Threshold
                              </label>
                              <input
                                type="number"
                                step="0.01"
                                className="form-control"
                                name="frontRightMinSecondPeakAmplitudeThreshold"
                                id="frontRightMinSecondPeakAmplitudeThreshold"
                                placeholder="Enter Front Right Min Threshold"
                                value={
                                  modelFormData.frontRightMinSecondPeakAmplitudeThreshold
                                } // Bind value to formData
                                onChange={handleModelFormData}
                              />
                              {
                                selectedModel.wheelMaster
                                  .filter(
                                    (wheel) =>
                                      wheel.wheelPosition === "FRONT" &&
                                      wheel.wheelSide === "RIGHT"
                                  )
                                  .map((wheel, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        fontSize: "1rem",
                                        color: "#6c757d",
                                        marginTop: "2px",
                                      }}
                                    >
                                      {wheel.minSecondPeakAmplitudeThreshold}
                                    </div>
                                  ))[0] /* Display only the first front wheel's standard weight */
                              }
                            </div>
                          </div>

                          <div
                            className="form-row"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: "25px",
                            }}
                          >
                            <div
                              className="form-group"
                              style={{ flex: "0 0 48%" }}
                            >
                              <label
                                htmlFor="backLeftMinSecondPeakAmplitudeThreshold"
                                style={{ fontWeight: "bold" }}
                              >
                                Back Left Min Second Peak Amplitude Threshold
                              </label>
                              <input
                                type="number"
                                step="0.01"
                                className="form-control"
                                name="backLeftMinSecondPeakAmplitudeThreshold"
                                id="backLeftMinSecondPeakAmplitudeThreshold"
                                placeholder="Enter Back Left Min Threshold"
                                value={
                                  modelFormData.backLeftMinSecondPeakAmplitudeThreshold
                                } // Bind value to formData
                                onChange={handleModelFormData}
                              />
                              {
                                selectedModel.wheelMaster
                                  .filter(
                                    (wheel) =>
                                      wheel.wheelPosition === "BACK" &&
                                      wheel.wheelSide === "LEFT"
                                  )
                                  .map((wheel, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        fontSize: "1rem",
                                        color: "#6c757d",
                                        marginTop: "2px",
                                      }}
                                    >
                                      {wheel.minSecondPeakAmplitudeThreshold}
                                    </div>
                                  ))[0] /* Display only the first front wheel's standard weight */
                              }
                            </div>

                            <div
                              className="form-group"
                              style={{ flex: "0 0 48%" }}
                            >
                              <label
                                htmlFor="backRightMinSecondPeakAmplitudeThreshold"
                                style={{ fontWeight: "bold" }}
                              >
                                Back Right Min Second Peak Amplitude Threshold
                              </label>
                              <input
                                type="number"
                                step="0.01"
                                className="form-control"
                                name="backRightMinSecondPeakAmplitudeThreshold"
                                id="backRightMinSecondPeakAmplitudeThreshold"
                                placeholder="Enter Back Right Min Threshold"
                                value={
                                  modelFormData.backRightMinSecondPeakAmplitudeThreshold
                                } // Bind value to formData
                                onChange={handleModelFormData}
                              />
                              {
                                selectedModel.wheelMaster
                                  .filter(
                                    (wheel) =>
                                      wheel.wheelPosition === "BACK" &&
                                      wheel.wheelSide === "RIGHT"
                                  )
                                  .map((wheel, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        fontSize: "1rem",
                                        color: "#6c757d",
                                        marginTop: "2px",
                                      }}
                                    >
                                      {wheel.minSecondPeakAmplitudeThreshold}
                                    </div>
                                  ))[0] /* Display only the first front wheel's standard weight */
                              }
                            </div>
                          </div>

                          <div
                            className="form-row"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: "25px",
                            }}
                          >
                            <div
                              className="form-group"
                              style={{ flex: "0 0 48%" }}
                            >
                              <label
                                htmlFor="frontLeftSecondPeakAmplitudeThreshold"
                                style={{ fontWeight: "bold" }}
                              >
                                Front Left Max Second Peak Amplitude Threshold
                              </label>
                              <input
                                type="number"
                                step="0.01"
                                className="form-control"
                                name="frontLeftSecondPeakAmplitudeThreshold"
                                id="frontLeftSecondPeakAmplitudeThreshold"
                                placeholder="Enter Front Left Max Threshold"
                                value={
                                  modelFormData.frontLeftSecondPeakAmplitudeThreshold
                                } // Bind value to formData
                                onChange={handleModelFormData}
                              />
                              {
                                selectedModel.wheelMaster
                                  .filter(
                                    (wheel) =>
                                      wheel.wheelPosition === "FRONT" &&
                                      wheel.wheelSide === "LEFT"
                                  )
                                  .map((wheel, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        fontSize: "1rem",
                                        color: "#6c757d",
                                        marginTop: "2px",
                                      }}
                                    >
                                      {wheel.secondPeakAmplitudeThreshold}
                                    </div>
                                  ))[0] /* Display only the first front wheel's standard weight */
                              }
                            </div>

                            <div
                              className="form-group"
                              style={{ flex: "0 0 48%" }}
                            >
                              <label
                                htmlFor="frontRightSecondPeakAmplitudeThreshold"
                                style={{ fontWeight: "bold" }}
                              >
                                Front Right Max Second Peak Amplitude Threshold
                              </label>
                              <input
                                type="number"
                                step="0.01"
                                className="form-control"
                                name="frontRightSecondPeakAmplitudeThreshold"
                                id="frontRightSecondPeakAmplitudeThreshold"
                                placeholder="Enter Front Right Max Threshold"
                                value={
                                  modelFormData.frontRightSecondPeakAmplitudeThreshold
                                } // Bind value to formData
                                onChange={handleModelFormData}
                              />
                              {
                                selectedModel.wheelMaster
                                  .filter(
                                    (wheel) =>
                                      wheel.wheelPosition === "FRONT" &&
                                      wheel.wheelSide === "RIGHT"
                                  )
                                  .map((wheel, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        fontSize: "1rem",
                                        color: "#6c757d",
                                        marginTop: "2px",
                                      }}
                                    >
                                      {wheel.secondPeakAmplitudeThreshold}
                                    </div>
                                  ))[0] /* Display only the first front wheel's standard weight */
                              }
                            </div>
                          </div>

                          {/* Second Peak Amplitude Thresholds */}
                          <div
                            className="form-row"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: "25px",
                            }}
                          >
                            <div
                              className="form-group"
                              style={{ flex: "0 0 48%" }}
                            >
                              <label
                                htmlFor="backLeftSecondPeakAmplitudeThreshold"
                                style={{ fontWeight: "bold" }}
                              >
                                Back Left Max Second Peak Amplitude Threshold
                              </label>
                              <input
                                type="number"
                                step="0.01"
                                className="form-control"
                                name="backLeftSecondPeakAmplitudeThreshold"
                                id="backLeftSecondPeakAmplitudeThreshold"
                                placeholder="Enter Back Left Max Threshold"
                                value={
                                  modelFormData.backLeftSecondPeakAmplitudeThreshold
                                } // Bind value to formData
                                onChange={handleModelFormData}
                              />
                              {
                                selectedModel.wheelMaster
                                  .filter(
                                    (wheel) =>
                                      wheel.wheelPosition === "BACK" &&
                                      wheel.wheelSide === "LEFT"
                                  )
                                  .map((wheel, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        fontSize: "1rem",
                                        color: "#6c757d",
                                        marginTop: "2px",
                                      }}
                                    >
                                      {wheel.secondPeakAmplitudeThreshold}
                                    </div>
                                  ))[0] /* Display only the first front wheel's standard weight */
                              }
                            </div>

                            <div
                              className="form-group"
                              style={{ flex: "0 0 48%" }}
                            >
                              <label
                                htmlFor="backRightSecondPeakAmplitudeThreshold"
                                style={{ fontWeight: "bold" }}
                              >
                                Back Right Max Second Peak Amplitude Threshold
                              </label>
                              <input
                                type="number"
                                step="0.01"
                                className="form-control"
                                name="backRightSecondPeakAmplitudeThreshold"
                                id="backRightSecondPeakAmplitudeThreshold"
                                placeholder="Enter Back Right Max Threshold"
                                value={
                                  modelFormData.backRightSecondPeakAmplitudeThreshold
                                } // Bind value to formData
                                onChange={handleModelFormData}
                              />
                              {
                                selectedModel.wheelMaster
                                  .filter(
                                    (wheel) =>
                                      wheel.wheelPosition === "BACK" &&
                                      wheel.wheelSide === "RIGHT"
                                  )
                                  .map((wheel, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        fontSize: "1rem",
                                        color: "#6c757d",
                                        marginTop: "2px",
                                      }}
                                    >
                                      {wheel.secondPeakAmplitudeThreshold}
                                    </div>
                                  ))[0] /* Display only the first front wheel's standard weight */
                              }
                            </div>
                          </div>
                          {/*Vehicle Body Type */}
                          <div
                            className="form-row"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: "25px",
                            }}
                          >
                            <div
                              className="form-group"
                              style={{ flex: "0 0 48%" }}
                            >
                              <label
                                htmlFor="vehicleBodyType"
                                style={{ fontWeight: "bold" }}
                              >
                                Select Vehicle Body Type
                              </label>

                              <Select
                                value={
                                  selectedVehicleBodyType
                                    ? {
                                        value: selectedVehicleBodyType.id,
                                        label: selectedVehicleBodyType.bodyType,
                                      }
                                    : {
                                        value: null,
                                        label: "Select Body Type",
                                      }
                                }
                                styles={{
                                  control: (provided) => ({
                                    ...provided,
                                    width: "100%",
                                  }),
                                  menu: (provided) => ({
                                    ...provided,
                                    width: "100%",
                                    zIndex: "999",
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                  }),
                                }}
                                options={[
                                  {
                                    value: null,
                                    label: "Select Body Type",
                                  },
                                  ...vehicleBodyTypeDropDown
                                    .map((option) => ({
                                      value: option.id,
                                      label: option.bodyType,
                                    }))
                                    .sort((a, b) =>
                                      a.label.localeCompare(b.label)
                                    ), // Sort alphabetically by label
                                ]}
                                placeholder="Select"
                                isSearchable
                                onChange={(selectedOption) => {
                                  if (selectedOption.value === null) {
                                    setSelectedVehicleBodyType(null);
                                  } else {
                                    const selectedBodyType =
                                      vehicleBodyTypeDropDown.find(
                                        (option) =>
                                          option.id === selectedOption.value
                                      );
                                    setSelectedVehicleBodyType(
                                      selectedBodyType
                                    );
                                  }
                                }}
                              />
                              {selectedModel.vehicleBodyType.bodyType && (
                                <div
                                  style={{
                                    fontSize: "1rem",
                                    color: "#6c757d",
                                    marginTop: "2px",
                                  }}
                                >
                                  {selectedModel.vehicleBodyType.bodyType}
                                </div>
                              )}
                            </div>
                          </div>
                        </Form>
                      )}
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        className="btn btn-secondary"
                        onClick={() => setShowEditModal(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="btn btn-primary"
                        // onClick={handleSaveChanges}
                        onClick={handleAddClick}
                        disabled={!isModelFormValid()}
                      >
                        Update
                      </Button>
                    </Modal.Footer>
                  </Modal>

                  <Modal
                    show={showReConfirmDialog}
                    onHide={handleCancelDilogBox}
                    dialogClassName="my-modal"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Reconfirm Update</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div style={{ marginBottom: "15px", fontSize: "1.2rem" }}>
                        Do you really want to apply these modifications to the
                        model?
                      </div>
                      <table
                        className="table no-border"
                        style={{
                          borderCollapse: "collapse",
                          width: "100%",
                          textAlign: "left",
                        }}
                      >
                        <thead>
                          <tr>
                            {" "}
                            {/* Background color is now transparent */}
                            <th style={{ textAlign: "left" }}>Field</th>
                            <th style={{ textAlign: "left" }}>Current</th>
                            <th style={{ textAlign: "left" }}>New</th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* Make Name */}
                          {modelFormData.makeName && (
                            <tr>
                              <td style={{ textAlign: "left" }}>Make Name</td>
                              <td style={{ textAlign: "left" }}>
                                {selectedModel.makeName}
                              </td>
                              <td style={{ textAlign: "left" }}>
                                {modelFormData.makeName}
                              </td>
                            </tr>
                          )}

                          {/* Model Name */}
                          {modelFormData.modelName && (
                            <tr>
                              <td style={{ textAlign: "left" }}>Model Name</td>
                              <td style={{ textAlign: "left" }}>
                                {selectedModel.modelName}
                              </td>
                              <td style={{ textAlign: "left" }}>
                                {modelFormData.modelName}
                              </td>
                            </tr>
                          )}

                          {/* Vehicle Body Type */}
                          {selectedVehicleBodyType &&
                            selectedVehicleBodyType.bodyType && (
                              <tr>
                                <td style={{ textAlign: "left" }}>
                                  Vehicle Body Type
                                </td>
                                <td style={{ textAlign: "left" }}>
                                  {selectedModel.vehicleBodyType.bodyType}
                                </td>
                                <td style={{ textAlign: "left" }}>
                                  {selectedVehicleBodyType.bodyType}
                                </td>
                              </tr>
                            )}

                          {/* Wheel positions and standard weights */}
                          {Array.from(
                            new Set(
                              selectedModel.wheelMaster.map(
                                (wheel) => wheel.wheelPosition
                              )
                            )
                          )
                            .sort((a, b) => b.localeCompare(a)) // Sort positions in descending order
                            .map((position) => {
                              const wheel = selectedModel.wheelMaster.find(
                                (w) => w.wheelPosition === position
                              );
                              const newWeight =
                                modelFormData[
                                  `${position.toLowerCase()}Weight`
                                ];
                              if (newWeight) {
                                return (
                                  <tr key={position}>
                                    <td style={{ textAlign: "left" }}>
                                      {position.charAt(0).toUpperCase() +
                                        position.slice(1).toLowerCase()}{" "}
                                      Wheel Standard Weight
                                    </td>
                                    <td style={{ textAlign: "left" }}>
                                      {wheel.standardWeight}
                                    </td>
                                    <td style={{ textAlign: "left" }}>
                                      {newWeight}
                                    </td>
                                  </tr>
                                );
                              }
                              return null;
                            })}

                          {/* Front Left MIN SPA Threshold */}
                          {modelFormData.frontLeftMinSecondPeakAmplitudeThreshold && (
                            <tr>
                              <td style={{ textAlign: "left" }}>
                                Front Left Min SPA Threshold
                              </td>
                              <td style={{ textAlign: "left" }}>
                                {
                                  selectedModel.wheelMaster.find(
                                    (wheel) =>
                                      wheel.wheelPosition === "FRONT" &&
                                      wheel.wheelSide === "LEFT"
                                  )?.minSecondPeakAmplitudeThreshold
                                }
                              </td>
                              <td style={{ textAlign: "left" }}>
                                {
                                  modelFormData.frontLeftMinSecondPeakAmplitudeThreshold
                                }
                              </td>
                            </tr>
                          )}

                          {/* Front Right MIN SPA Threshold */}
                          {modelFormData.frontRightMinSecondPeakAmplitudeThreshold && (
                            <tr>
                              <td style={{ textAlign: "left" }}>
                                Front Right Min SPA Threshold
                              </td>
                              <td style={{ textAlign: "left" }}>
                                {
                                  selectedModel.wheelMaster.find(
                                    (wheel) =>
                                      wheel.wheelPosition === "FRONT" &&
                                      wheel.wheelSide === "RIGHT"
                                  )?.minSecondPeakAmplitudeThreshold
                                }
                              </td>
                              <td style={{ textAlign: "left" }}>
                                {
                                  modelFormData.frontRightMinSecondPeakAmplitudeThreshold
                                }
                              </td>
                            </tr>
                          )}

                          {/* Back Left MIN SPA Threshold */}
                          {modelFormData.backLeftMinSecondPeakAmplitudeThreshold && (
                            <tr>
                              <td style={{ textAlign: "left" }}>
                                Back Left Min SPA Threshold
                              </td>
                              <td style={{ textAlign: "left" }}>
                                {
                                  selectedModel.wheelMaster.find(
                                    (wheel) =>
                                      wheel.wheelPosition === "BACK" &&
                                      wheel.wheelSide === "LEFT"
                                  )?.minSecondPeakAmplitudeThreshold
                                }
                              </td>
                              <td style={{ textAlign: "left" }}>
                                {
                                  modelFormData.backLeftMinSecondPeakAmplitudeThreshold
                                }
                              </td>
                            </tr>
                          )}

                          {/* Back Right MIN SPA Threshold */}
                          {modelFormData.backRightMinSecondPeakAmplitudeThreshold && (
                            <tr>
                              <td style={{ textAlign: "left" }}>
                                Back Right Min SPA Threshold
                              </td>
                              <td style={{ textAlign: "left" }}>
                                {
                                  selectedModel.wheelMaster.find(
                                    (wheel) =>
                                      wheel.wheelPosition === "BACK" &&
                                      wheel.wheelSide === "RIGHT"
                                  )?.minSecondPeakAmplitudeThreshold
                                }
                              </td>
                              <td style={{ textAlign: "left" }}>
                                {
                                  modelFormData.backRightMinSecondPeakAmplitudeThreshold
                                }
                              </td>
                            </tr>
                          )}

                          {/* Front Left MAX SPA Threshold */}
                          {modelFormData.frontLeftSecondPeakAmplitudeThreshold && (
                            <tr>
                              <td style={{ textAlign: "left" }}>
                                Front Left Max SPA Threshold
                              </td>
                              <td style={{ textAlign: "left" }}>
                                {
                                  selectedModel.wheelMaster.find(
                                    (wheel) =>
                                      wheel.wheelPosition === "FRONT" &&
                                      wheel.wheelSide === "LEFT"
                                  )?.secondPeakAmplitudeThreshold
                                }
                              </td>
                              <td style={{ textAlign: "left" }}>
                                {
                                  modelFormData.frontLeftSecondPeakAmplitudeThreshold
                                }
                              </td>
                            </tr>
                          )}

                          {/* Front Right MAX SPA Threshold */}
                          {modelFormData.frontRightSecondPeakAmplitudeThreshold && (
                            <tr>
                              <td style={{ textAlign: "left" }}>
                                Front Right Max SPA Threshold
                              </td>
                              <td style={{ textAlign: "left" }}>
                                {
                                  selectedModel.wheelMaster.find(
                                    (wheel) =>
                                      wheel.wheelPosition === "FRONT" &&
                                      wheel.wheelSide === "RIGHT"
                                  )?.secondPeakAmplitudeThreshold
                                }
                              </td>
                              <td style={{ textAlign: "left" }}>
                                {
                                  modelFormData.frontRightSecondPeakAmplitudeThreshold
                                }
                              </td>
                            </tr>
                          )}

                          {/* Back Left MAX SPA Threshold */}
                          {modelFormData.backLeftSecondPeakAmplitudeThreshold && (
                            <tr>
                              <td style={{ textAlign: "left" }}>
                                Back Left Max SPA Threshold
                              </td>
                              <td style={{ textAlign: "left" }}>
                                {
                                  selectedModel.wheelMaster.find(
                                    (wheel) =>
                                      wheel.wheelPosition === "BACK" &&
                                      wheel.wheelSide === "LEFT"
                                  )?.secondPeakAmplitudeThreshold
                                }
                              </td>
                              <td style={{ textAlign: "left" }}>
                                {
                                  modelFormData.backLeftSecondPeakAmplitudeThreshold
                                }
                              </td>
                            </tr>
                          )}

                          {/* Back Right MAX SPA Threshold */}
                          {modelFormData.backRightSecondPeakAmplitudeThreshold && (
                            <tr>
                              <td style={{ textAlign: "left" }}>
                                Back Right Max SPA Threshold
                              </td>
                              <td style={{ textAlign: "left" }}>
                                {
                                  selectedModel.wheelMaster.find(
                                    (wheel) =>
                                      wheel.wheelPosition === "BACK" &&
                                      wheel.wheelSide === "RIGHT"
                                  )?.secondPeakAmplitudeThreshold
                                }
                              </td>
                              <td style={{ textAlign: "left" }}>
                                {
                                  modelFormData.backRightSecondPeakAmplitudeThreshold
                                }
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={handleCancelDilogBox}
                      >
                        Cancel
                      </Button>
                      <Button variant="primary" onClick={handleReConfirm}>
                        Confirm
                      </Button>
                    </Modal.Footer>
                  </Modal>

                  <Modal show={showConfirmDialog} onHide={handleCancelDilog}>
                    <Modal.Header closeButton>
                      <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      Are you sure you want to Update this model?
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleCancelDilog}>
                        Cancel
                      </Button>
                      <Button variant="primary" onClick={handleConfirm}>
                        Confirm
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  {/* success Dialog Box*/}
                  <Modal
                    show={isSuccessModalVisible}
                    onHide={handleCloseSuccessModal}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Success</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Model Update successfully..!</Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={handleCloseSuccessModal}
                      >
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Update;
